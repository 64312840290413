<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/home-hero-2.png')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .6), rgba(5, 11, 31, .2)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading>
            Scrum<span style="font-weight:normal">On</span>Demand
          </base-heading>

          <base-body>
            <span class="white--text">Join us in bringing Clients and Talent together in a positive and sustaining way, building communities of enriched people,  empowered companies, and propelling a cycle of continuous growth.</span>
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
              :to="{ name: 'Discover' }"
            >
              Discover More
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
              href="https://main.scrumteamondemand.com/authentication/register?rfr=home"
              target="_blank"
              rel="noopener"
            >
              <span v-if="!isBeta">Get Started Now</span>
              <span v-if="isBeta">Get Started with our Beta launch</span>
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },
    data: () => ({
      isBeta: false,
    }),
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

  }
</script>
